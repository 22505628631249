<template lang="pug">
div
  div(v-show="!sendForm")
    .modal-header
      button.close(
        type="button"
        data-dismiss="modal"
        aria-label="Close"
      )
        span ×
      h4.modal-title(v-html="formTitle")
    .modal-body
      .form-group
        input.form-control(
          type="text"
          v-model="fieldName"
          :placeholder="formFieldNamePlaceholder"
        )
      .form-group
        input.form-control(
          type="tel"
          v-model="fieldPhone"
          :placeholder="formFieldPhonePlaceholder"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        )
      .form-group
        select.form-control(
          v-model="fieldProduct"
        )
          option(disabled value="" v-html="formFieldProductPlaceholder")
          option Грунты
          option Песок
          option Щебень
          option Навоз
          option ПГС
          option Гранитный отсев
          option Керамзит
          option Озеленение
          option Ландшафтный дизайн
          option Вывоз земли

      .form-group
        input.form-control(
          type="text"
          v-model="fieldAddress"
          :placeholder="formFieldAddressPlaceholder"
        )
      .form-group
        textarea.form-control(
          v-model="fieldMessage"
          :placeholder="formFieldMessagePlaceholder"
        )

    .modal-footer
      .btn.bg_green.tc_white.bg_black_h(
          :class="{'button-disable': v$.$invalid}"
          @click="getSend()"
          v-html="formButtonName"
      )
      p(v-html="formConsent")

  div(v-show="sendForm")
    .modal-header
      h4.modal-title(v-html="formSuccessTitle")
    .modal-body
      p(v-html="formSuccessText")

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
    name: 'AppFormPopup',

    setup () {
        return { v$: useVuelidate() }
    },

    props: [
      "formSettingTitle",
      "formSettingSubject",

      "formTitle",
      "formButtonName",

      "formFieldName",
      "formFieldNamePlaceholder",
      "formFieldPhone",
      "formFieldPhonePlaceholder",
      "formFieldProduct",
      "formFieldProductPlaceholder",
      "formFieldAddress",
      "formFieldAddressPlaceholder",
      "formFieldMessage",
      "formFieldMessagePlaceholder",

      "formConsent",

      "formSuccessTitle",
      "formSuccessText",

      "formPageLabel",
      "formPageId",
      "formPageTitle",
    ],

    data () {
        return {
          fieldName: '',
          fieldPhone: '',
          fieldProduct: '',
          fieldAddress: '',
          fieldMessage: '',
          files: '',
          sendForm: false
        }
    },

    methods: {
        ...mapActions([
          'Send'
        ]),

        getSend () {
            if (!this.v$.$invalid) {
                this.sendForm = !this.sendForm

                this.Send({
                    setting: {
                        title: this.formSettingTitle,
                        subject: this.formSettingSubject
                    },

                    fields: [
                        {
                            title: this.formFieldName,
                            value: this.fieldName
                        },
                        {
                          title: this.formFieldPhone,
                            value: this.fieldPhone
                        },
                        {
                          title: this.formFieldProduct,
                          value: this.fieldProduct
                        },
                        {
                          title: this.formFieldAddress,
                          value: this.fieldAddress
                        },
                        {
                          title: this.formFieldMessage,
                          value: this.fieldMessage
                        },
                        {
                          title: this.formPageLabel,
                          value: this.formPageTitle + ' (' + this.formPageId + ')'
                        }
                    ],
                    files: this.files
                })

                this.fieldName = this.fieldPhone = this.fieldAddress = this.fieldMessage = ''

                setTimeout(() => {
                  this.sendForm = !this.sendForm
                }, 3500)
            }
        }
    },

    validations () {
        return {
            fieldName: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(40)
            },

            fieldPhone: {
              required,
              minLength: minLength(18),
              maxLength: maxLength(18)
            }
        }
    }
}
</script>

<style scoped>
  .button-disable {
    cursor: default;
    filter: grayscale(100%);
  }
</style>
