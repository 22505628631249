/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import AppFormPopup from '@/components/Form/Popup/AppFormPopup.vue'
import AppForm from '@/components/Form/AppForm.vue'

document.addEventListener('DOMContentLoaded', () => {
    if(document.getElementById('block--form-popup')!= null) {
        createApp({})
            .component('form-popup', AppFormPopup)
            .use(Maska)
            .use(form)
            .mount('#block--form-popup')
    }

    if(document.getElementById('block--form')!= null) {
        createApp({})
            .component('form-block', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form')
    }
})
