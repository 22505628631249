<template lang="pug">
.text-center.well
  div(v-show="!sendForm")
    .form-header
      .h2(v-html="formTitle")

    .form-body
      .row
        .col-md-4.form-group
          input.form-control(
            type="text"
            :placeholder="formFieldNamePlaceholder"
            v-model="fieldName"
          )
        .col-md-4.form-group
          input.form-control(
            type="tel"
            :placeholder="formFieldPhonePlaceholder"
            v-model="fieldPhone"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          )
        .col-md-4.form-group
          input.form-control(
            type="email"
            :placeholder="formFieldEmailPlaceholder"
            v-model="fieldEmail"
          )

    .form-footer
      .btn.btn_standart.bg_green.tc_white.tc_green_h.bg_white_h(
        :class="{'button-disable': v$.$invalid}"
        @click="getSend()"
        v-html="formButtonName"
      )
  div(v-show="sendForm")
    .form-header
      .h2(v-html="formSuccessTitle")

    .form-body
      p(v-html="formSuccessText")

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppForm',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formTitle",
    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldEmail",
    "formFieldEmailPlaceholder",

    "formConsent",

    "formSuccessTitle",
    "formSuccessText",

    "formPageLabel",
    "formPageId",
    "formPageTitle",
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      fieldEmail: '',
      files: '',
      sendForm: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldEmail,
              value: this.fieldEmail
            },
            {
              title: this.formPageLabel,
              value: this.formPageTitle + ' (' + this.formPageId + ')'
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = this.fieldEmail = ''

        setTimeout(() => {
          this.sendForm = !this.sendForm
        }, 3500)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      }
    }
  }
}
</script>

<style scoped>
.button-disable {
  cursor: default;
  filter: grayscale(100%);
}
</style>
